import React from "react";
import PurchaseConfirmationComponent from "./PurchaseConfirmationComponent";
import "./index.css";
export default function PurchaseConfirmation() {
  return (
    <div className="container py-4 " style={{ minHeight: "65vh" }}>
      <PurchaseConfirmationComponent />
    </div>
  );
}
